import { ArrowDownBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import PopOverSlot from '@/core/components/documents/PopOverSlot.vue';
import Button from '@/core/components/ui/Button.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
const DELETE = 'Delete';
const EDIT = 'Edit';
export default defineComponent({
    components: {
        UserThumbnail,
        Button,
        ArrowDownBold,
        PopOverSlot
    },
    emits: ['show-delete-modal', 'edit-client', 'on-select'],
    props: ['templates'],
    data() {
        return {
            /* eslint-disable global-require */
            popOverData: [
                // {
                //   icon: `${require('@/assets/icons/filled-edit-icon.svg')}`,
                //   name: 'Modify',
                // },
                // {
                //   icon: `${require('@/assets/icons/filled-share-icon.svg')}`,
                //   name: 'Share',
                // },
                {
                    icon: `${require('@/assets/icons/filled-details-icon.svg')}`,
                    name: EDIT,
                },
                // {
                //   icon: `${require('@/assets/icons/filled-download-icon.svg')}`,
                //   name: 'Download',
                // },
                {
                    icon: `${require('@/assets/icons/delete-icon.svg')}`,
                    name: DELETE,
                },
            ],
        };
    },
    async created() {
        this.initialized();
    },
    methods: {
        initialized() {
        },
        onClickMenu(eventName, entity) {
            if (eventName === DELETE) {
                this.$emit('show-delete-modal', entity);
            }
            if (eventName === EDIT) {
                this.$emit('edit-client', entity);
            }
        },
        onSelect(id) {
            this.$emit('on-select', id);
        }
    }
});
