<template>
    <div class="templates-page flex-column">
      <section class="templates-page__container sticky flex-row ai-c jc-sb" v-if="!isOnEstimateForm">
        <PageTitleWithCountComponent title="Templates" :totalItemCount="templates.length"/>
        <DisplayViewComponent
          :viewType="viewType"
          @set-view="setView"
          :showAddButton="true"
          actionText="New template"
          @add-function="onOpenAddTemplateNameModal"/>
      </section>
      <section
        class="templates-page__container"
        :class="{'grid': viewType === display.TILE}"
        v-if="templates.length && !isOnEstimateForm && !loadingTemplates">
        <TemplatesList
          :templates="templates"
          v-if="viewType === display.LIST"
          @on-select="openUseConfirmationModal"
          @edit-client="onEditClient($event)"
          @show-delete-modal="onOpenDeleteModal($event)"/>
        <template v-if="viewType === display.TILE">
          <TemplateTileViewItem v-for="(template, index) in templates" :key="index" :data="template"/>
        </template>
      </section>
      <section class="templates-page__container grid-2fr" v-if="isOnEstimateForm && !loadingTemplates">
        <EstimateForm
          :isTemplateCreate="true"
          @on-calculate="calculateFee" />
        <EstimateSummary
          :isTemplateCreate="true"
          @back-to-list="backToTemplateListList"/>
      </section>

      <EmptyState
        style="top: calc(50% - 137px) !important; left: calc(50% - 150px) !important;"
        v-if="!templates.length && !isOnEstimateForm && !loadingTemplates"
        icon="empty-templates-icon.svg"
        message="You don’t currently have any templates"
        :hasCustomContent="true">
        <Button
          @handle-click="onOpenAddTemplateNameModal"
          buttonText="Create new template"
          :isActive="true"
          activeColor="#4F55F0"
          style="margin-top: 1.5rem !important;"/>
      </EmptyState>

      <Loader :show="loadingTemplates" />

      <!-- MODALS -->
      <AddTemplateNameModal
        :show="openAddTemplateNameModal"
        @close="onCloseTemplateNameModal"
        @on-save="onSaveTemplateName"/>
      <ConfirmUseTemplateModal
        :show="showUseTemplateModal"
        @close="onCloseUseConfirmationModal"/>
      <ConfirmDeleteTemplateModal
        :show="confirmDeleteModal"
        :loading="loadingDelete"
        @on-confirm="onDeleteTemplate"
        @on-cancel="onCloseConfirmDeleteModal"/>
    </div>
</template>
<script>
// import _ from 'lodash';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import EstimateForm from '@/core/components/common/forms/estimate-form/EstimateForm.vue';
import EstimateSummary from '@/core/components/common/forms/estimate-form/EstimateSummary.vue';
import PageTitleWithCountComponent from '@/core/components/common/header/PageTitleWithCountComponent.vue';
import TemplatesList from '@/core/components/common/list/TemplatesList.vue';
import AddTemplateNameModal from '@/core/components/common/modals/AddTemplateNameModal.vue';
import ConfirmDeleteTemplateModal from '@/core/components/common/modals/ConfirmDeleteTemplateModal.vue';
import ConfirmUseTemplateModal from '@/core/components/common/modals/ConfirmUseTemplateModal.vue';
import TemplateTileViewItem from '@/core/components/common/tile/TemplateTileViewItem.vue';
import Button from '@/core/components/ui/Button.vue';
import EmptyState from '@/core/components/ui/EmptyState.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { DISPLAY_DATE_FORMAT } from '@/core/constants';
import display from '@/core/constants/display';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import appFilters from '@/filters';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';

export default defineComponent({
  components: {
    PageTitleWithCountComponent,
    DisplayViewComponent,
    TemplatesList,
    TemplateTileViewItem,
    AddTemplateNameModal,
    EmptyState,
    Button,
    EstimateSummary,
    EstimateForm,
    ConfirmUseTemplateModal,
    Loader,
    ConfirmDeleteTemplateModal
  },
  data() {
    return {
      display,
      viewType: display.LIST,
      openAddTemplateNameModal: false,

      summaryTotal: null,

      templates: [],

      loadingTemplates: false,

      loadingDelete: false,

      selectedTemplateId: null,
    };
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, [
      'showUseTemplateModal',
      'estimateForm',
      'defaultEstimateForm',
      'isOnEstimateForm',
      'confirmDeleteModal',
      'templateName'
    ]),

    currentRouteName() {
      return this.$route.name;
    }
  },

  watch: {
    async currentRouteName(newRouteName, oldRouteName) {
      if (newRouteName !== oldRouteName) {
        await this.initialize();
      }
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions(JOB_TEMPLATE_STORE, [
      'getMyJobTemplates',
      'getJobTemplate',
      'setShowUseTemplateModal',
      'setEstimateForm',
      'setSelectedJobTemplate',
      'setTemplateName',
      'setIsOnEstimateForm',
      'setConfirmDeleteModal',
      'deleteMyJobTemplate'
    ]),

    ...mapActions(PROJECT_QUOTES_STORE, [
      'calculateServiceFee',
      'setTotalFee'
    ]),

    async initialize() {
      // is edit/view?
      const { params } = this.$route;
      this.loadingTemplates = true;

      if (params?.id) {
        await this.getJobTemplate(params.id)
          .then(async (jobTemplate) => {
            if (jobTemplate) {
              const { meta, name } = jobTemplate;

              this.setIsOnEstimateForm(true);

              this.setSelectedJobTemplate(jobTemplate);
              this.setTemplateName(name);

              if (meta?.projectStages) {
                this.setEstimateForm({ projectStages: meta?.projectStages });
                await this.calculateFee();
              }
            }
          }).catch(() => {})
          .finally(async () => {
            this.loadingTemplates = false;
          });

        return;
      }

      await this.getMyJobTemplates()
        .then((response) => {
          this.setIsOnEstimateForm(false);
          this.templates = response.data.map((item) => {
            return {
              ...item,
              owner: 'Me',
              lastModified: appFilters.formatToDate(item.dateUpdated, DISPLAY_DATE_FORMAT)
            };
          });
        }).catch(() => {})
        .finally(() => {
          this.loadingTemplates = false;
        });

      // odd object reference, this should be the default
      this.setEstimateForm({
        projectStages: [
          {
            name: '',
            stageType: 'labour',
            totalDuration: 0,
            durationType: 1,
            stageCost: 0,
            projectStageTasks: [],
            isNew: true,
            isUsePhaseTotal: null,
            requiredDeposit: false,
            vat: 0,
            subTotal: 0,
            note: ''
          }
        ]
      });
    },

    setView(view) {
      this.viewType = view;
    },

    setSummaryTotal(summary) {
      this.summaryTotal = summary;
    },

    onOpenAddTemplateNameModal() {
      this.openAddTemplateNameModal = true;
    },

    onCloseTemplateNameModal() {
      this.openAddTemplateNameModal = false;
    },

    onSaveTemplateName() {
      if (this.hasTemplateNameDuplicate()) {
        this.$notify.error({
          title: 'Invalid name',
          message: 'Template name already exist. Please enter a unique name.',
        });
      } else {
        this.setIsOnEstimateForm(true);
        this.onCloseTemplateNameModal();
      }
    },

    hasTemplateNameDuplicate() {
      const { templates, templateName } = this;

      const templateNameExist = templates.find((template) => template.name.toLowerCase() === templateName.toLowerCase());

      return !!templateNameExist;
    },

    async backToTemplateListList() {
      const { params } = this.$route;
      if (params?.id) {
        this.$router.replace({ name: 'templates' });
      } else {
        await this.initialize();
      }
      this.setIsOnEstimateForm(false);
    },

    openUseConfirmationModal() {
      this.setShowUseTemplateModal(true);
    },

    onCloseUseConfirmationModal() {
      this.setShowUseTemplateModal(false);
    },

    async onEditClient(client) {
      await this.$router.replace({ name: 'template-edit', params: { id: client.id } });
    },

    onCloseConfirmDeleteModal() {
      this.selectedTemplateId = null;
      this.setConfirmDeleteModal(false);
    },

    onOpenDeleteModal(template) {
      this.selectedTemplateId = template?.id;
      this.setConfirmDeleteModal(true);
    },

    async onDeleteTemplate() {
      this.loadingDelete = true;
      const { selectedTemplateId } = this;

      if (selectedTemplateId !== null && selectedTemplateId) {
        try {
          await this.deleteMyJobTemplate(selectedTemplateId);
          this.$notify.success({
            title: 'Deleted',
            message: 'Successfully Deleted the template',
          });
        } catch (error) {
          this.$notify.error({
            title: 'Template Deletion Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        } finally {
          await this.initialize();
          this.loadingDelete = false;
          this.onCloseConfirmDeleteModal();
        }
      }
    },

    async calculateFee() {
      const totalFee = await this.calculateServiceFee({
        projectId: 999, // spoof
        projectStages: this.estimateForm.projectStages,
      });
      this.setTotalFee(totalFee);
    },
  }
});
</script>
<style lang="scss" scoped>
.templates-page {
  position: relative;
  height: 100%;

  &__container {
    padding-bottom: 2rem;
    box-sizing: border-box;
  }

  &__container.sticky {
    background: #F1F6FB;
    position: sticky;
    top: 0;
    z-index: 101;
    padding-bottom: 1rem;
    box-sizing: border-box;
  }
  &__container.grid {
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    gap: 2rem;
  }
  &__container.grid-2fr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  &__container.jc-fe {
    justify-content: flex-end !important;
  }
}
</style>
