<template>
    <DefaultCardSlot
      class="template-tile-view"
      :popOverData="popOverData"
      :showDropdownMenu="true"
      cardHeight="250px">
      <div class="flex-column jc-sb" style="height: 100%;">
        <div class="flex-column ai-c jc-c gap-half">
           <inline-svg :src="require(`@/assets/icons/template-icon.svg`)"></inline-svg>
           <p class="template-tile-view__text bold default-text">{{ data?.name }}</p>
        </div>
        <div class="flex-row ai-c jc-c" style="width: 100%;">
          <Button
            isActive="true"
            activeColor="#4F55F0"
            buttonText="use"
            @handle-click="openUseConfirmTemplateModal"/>
        </div>
      </div>
    </DefaultCardSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import Button from '@/core/components/ui/Button.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';

export default defineComponent({
  components: {
    DefaultCardSlot,
    Button,
  },
  props: ['data'],
  data() {
    return {
      /* eslint-disable global-require */
      popOverData: [
        {
          icon: `${require('@/assets/icons/filled-edit-icon.svg')}`,
          name: 'Modify',
        },
        {
          icon: `${require('@/assets/icons/filled-share-icon.svg')}`,
          name: 'Share',
        },
        {
          icon: `${require('@/assets/icons/filled-details-icon.svg')}`,
          name: 'Details',
        },
        {
          icon: `${require('@/assets/icons/filled-download-icon.svg')}`,
          name: 'Download',
        },
        {
          icon: `${require('@/assets/icons/delete-icon.svg')}`,
          name: 'Delete',
        },
      ],
      /* eslint-disable global-require */
    };
  },
  methods: {
    ...mapActions(JOB_TEMPLATE_STORE, ['setShowUseTemplateModal']),

    openUseConfirmTemplateModal() {
      this.setShowUseTemplateModal(true);
    }
  }
});
</script>
<style lang="scss" scoped>
.template-tile-view {
 &__text {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.1px;
 }
 &__text.bold {
    font-weight: 700;
    font-size: 20px;
 }
 &__text.small {
    font-size: 14px;
 }
}
</style>
